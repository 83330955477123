/**
 * CUSTOM VARIABLES
 * These variables are not originally defined in the Bootstrap framework, but are still globally being used within
 * the Voices.com application
 */

// Base Variables
@import '@styles/variables/bootstrap-variables';
@import '@styles/variables/color-variables';

// Utility Class Variables, that can be used throughout Design System codebase
@import '@styles/variables/borders-variables';
@import '@styles/variables/spacing-variables';

// Custom Variables for global Components
@import '@styles/variables/header-variables';

$assetPath: if(development = development, '/assets',
    if(development = systems, 'https://cdn.voices.systems/assets',
        'https://static.voices.com/assets'));

$themePath: if(development = development, '/themes/site_themes/voices/images',
    if(development = systems, 'https://cdn.voices.systems/themes/site_themes/voices/images',
        'https://static.voices.com/themes/site_themes/voices/images'));
