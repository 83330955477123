@use "sass:map";

@import "@styles/variables/spacing-variables";
@import "@styles/variables/color-variables";

$grid-breakpoints: (
	xxs: 0,
	xs: 480px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
) !default;

$container-max-widths: (
		sm: 750px,
		md: 970px,
		lg: 1170px
) !default;

$screen-xs: map.get($grid-breakpoints, xs);
$screen-sm: map.get($grid-breakpoints, sm);
$screen-md: map.get($grid-breakpoints, md);
$screen-lg: map.get($grid-breakpoints, lg);
$screen-xs-min: map.get($grid-breakpoints, xs);
$screen-sm-min: map.get($grid-breakpoints, sm);
$screen-md-min: map.get($grid-breakpoints, md);
$screen-lg-min: map.get($grid-breakpoints, lg);
$screen-xs-max: map.get($grid-breakpoints, sm) - 1px;
$screen-sm-max: map.get($grid-breakpoints, md) - 1px;
$screen-md-max: map.get($grid-breakpoints, lg) - 1px;

// Calculation Variables
$html-font-size-base: 10px;
$body-font-size-base: 18px;

// Typography *****************************************************************************
$font-family-sans-serif: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-base: calc(($body-font-size-base / $html-font-size-base) * 1rem);
$font-size-large: ($font-size-base * 1.25);
$font-size-small: ($font-size-base * 0.8);

$line-height-base: 1.5;

$font-weight-base: 400;
$font-weight-light: 300;
$font-weight-normal: $font-weight-base;
$font-weight-medium: 500;
$font-weight-bold: 700;


$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-sans-serif;
$headings-line-height: 1.25;

$font-sizes: (
	h1: 3.6rem,
	h2: 2.8rem,
	h3: 2.4rem,
	h4: 2rem,
	h5: 1.6rem,
	h6: 1.4rem,
);

$font-sizes-mobile: (
	h1: 2.8rem,
	h2: 2.2rem,
	h3: 2rem,
	h4: 1.8rem,
	h5: 1.6rem,
	h6: 1.4rem,
);

$font-weights: (
	h1: $font-weight-medium,
	h2: $font-weight-medium,
	h3: $font-weight-normal,
	h4: $font-weight-medium,
	h5: $font-weight-bold,
	h6: $font-weight-bold,
);

// Desktop Heading Sizes
$font-size-h1: map.get($font-sizes, h1);
$font-size-h2: map.get($font-sizes, h2);
$font-size-h3: map.get($font-sizes, h3);
$font-size-h4: map.get($font-sizes, h4);
$font-size-h5: map.get($font-sizes, h5);
$font-size-h6: map.get($font-sizes, h6);

// Mobile Headings Sizes
$font-size-h1-mobile: map.get($font-sizes-mobile, h1);
$font-size-h2-mobile: map.get($font-sizes-mobile, h2);
$font-size-h3-mobile: map.get($font-sizes-mobile, h3);
$font-size-h4-mobile: map.get($font-sizes-mobile, h4);
$font-size-h5-mobile: map.get($font-sizes-mobile, h5);
$font-size-h6-mobile: map.get($font-sizes-mobile, h6);

// Font Weights
$font-weight-h1: map.get($font-weights, h1);
$font-weight-h2: map.get($font-weights, h2);
$font-weight-h3: map.get($font-weights, h3);
$font-weight-h4: map.get($font-weights, h4);
$font-weight-h5: map.get($font-weights, h5);
$font-weight-h6: map.get($font-weights, h6);

// Paragraph
$paragraph-font-size: $font-size-base;
$paragraph-margin-bottom: 1.2rem;
$next-paragraph-margin-top: 1.8rem;

// Modals
$modal-lg: 900px;
$modal-md: 650px;
$modal-sm: 300px;

$spacers: (
	zero: 0,
	smallest: $spacing-smallest,
	smaller: $spacing-smaller,
	small: $spacing-small,
	medium: $spacing-medium,
	large: $spacing-large,
	larger: $spacing-larger,
	largest: $spacing-largest,
	huge: $spacing-huge,
	gigantic: $spacing-gigantic,
) !default;

$enable-cssgrid: true !default;
$enable-dark-mode: false !default;

$prefix: bs-;
$var-element:'--';

:root {
	@each $color, $value in $colors {
		#{$var-element}#{$prefix}#{"" + $color}: #{$value};
	}

	@each $color, $value in $theme-colors {
		#{$var-element}#{$prefix}#{"" + $color}-rgb: #{$value};
	}

	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: #{$grey4};
	--bs-white-rgb: #{$white};
	--bs-dropdown-bg: #{$white};
	--bs-modal-width: 650px;
	--bs-border-radius-sm: 0.25px;
}