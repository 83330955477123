@import '@styles/variables/global-variables';

// To prevent body from scrolling when search modal is opened.
// This class is only added on mobile.
body {
    &.expanded-search {
        height: 100vh;
        @media only screen and (max-width: 768px) {
            position: fixed;
            overflow-y: hidden;
        }
    }
    &.expanded {
        #header:not(.header-not-loggedin) {
            .nav-main {
                .nav-main-submenu {
                    position: relative;
                    z-index: -10;

                    &:has(.nav-profile.expanded) {
                        z-index: 1;
                    }
                }
            }
        }
    }
}


.siteSearch {
    &-container {
        position: relative;
        flex: 1 1 0%;
        padding: 0 2rem 0 1rem;
        @media only screen and (max-width: 768px) {
            padding: 0;
        }

        select {
            margin: 0;
            text-transform: none;
        }

        .form-control::-webkit-input-placeholder, ::-webkit-input-placeholder {
            color: #999;
        }

        ::placeholder {
            color: #999;
        }

        .radio-input {

            input {
                border: 1px solid $grey4;
                border-radius: 4px;
                height: 0;
                padding: .3em .6em;
                position: absolute;
                width: 0;
                display: none;

                &:checked {
                    +label {
                        background: $blue1;
                        color: $white;

                        &::before {
                            border-color: $white
                        }

                        &::after {
                            background-color: $white;
                            opacity: 1;
                        }
                    }
                }
            }

            label {
                position: relative;
                border: 1px solid $grey4;
                border-radius: .4rem;
                color: $grey1;
                cursor: pointer;
                display: block;
                font-weight: 500;
                margin: 0;
                padding: .5rem 1.2rem .4rem 3.6rem;

                &:hover {
                    color: #19222b;
                    background-color: $white;
                    border-color: #959ea8;

                    &::after {
                        opacity: 1;
                    }
                }

                &:active {
                background-color: #f0f4f7;
                border-color: #f0f4f7;
                color: #19222b;
                }

                &::before {
                    content: " ";
                    position: absolute;
                    display: block;
                    width: 1.6rem;
                    height: 1.6rem;
                    border: 1px solid $grey4;
                    border-radius: 9999px;
                    top: 0.8rem;
                    left: 1.2rem;
                }

                &::after {
                    content: " ";
                    width: 0.8rem;
                    height: 0.8rem;
                    position: absolute;
                    top: 1.2rem;
                    left: 1.6rem;
                    border-radius: 100%;
                    background-color: #959ea8;
                    opacity: 0;
                }
            }
        }

        .form-control {
            background-color: $white;
            border: 1px solid $grey4;
            border-radius: 4px;
            box-shadow: none;
            color: $grey1;
            display: block;
            font-size: 1.6rem !important;
            height: auto;
            line-height: 1.5;
            padding: 1.2rem;
            transition: border-color .15s ease-in-out;
            width: 100%;
        }

        .form-control:focus {
            border-color: #66afe9;
            box-shadow: rgba(0, 0, 0, .075) 0 1px 1px inset, rgba(102, 175, 233, .6) 0 0 8px;
            color: #313e4b;
            outline: 0;
        }

        select.form-control:focus {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .btn {
            font-size: 16px !important;
            font-weight: 500 !important;
        }

        .btn.btn-primary {
            background-color: $blue1 !important;
            border-color: transparent;
            color: #fff !important;
        }

        .btn.btn-primary:active, .btn.btn-primary:hover {
            border-color: transparent;
        }

        .btn.btn-primary:hover {
            background-color: #004a81 !important;
        }

        .btn.btn-primary:active {
            background-color: #1f8fe6 !important;
        }

        .btn.btn-primary:focus {
            background-color: $blue1 !important;
            border-color: transparent;
            box-shadow: #0098d5 0 0 0 2px;
            outline: 0;
        }

        .btn.btn-default {
            background-color: #f0f4f7 !important;
            border-color: transparent;
            color: #19222b !important;
        }

        .btn.btn-default:hover {
            background-color: $grey4 !important;
            border-color: transparent;
            color: #19222b !important;
        }

        .btn.btn-default:active {
            background-color: #959ea8 !important;
            border-color: transparent;
        }

        .btn.btn-default:focus {
            background-color: #f0f4f7 !important;
            border-color: transparent;
            box-shadow: #0098d5 0 0 0 2px;
            outline: 0;
        }

        .nav-search-list-item {
            position: relative;

            &::after {
                content: "\f054";
                width: 1rem;
                height: 2rem;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                font-family: "Font Awesome 5 Pro";
                font-size: 1.4rem;
                color: $grey2;
            }
        }

        &.siteSearch-mobileVisible {
            opacity: 1;
            visibility: visible;
            pointer-events: all;

            .siteSearch-dropdownContainer {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
            }

            .siteSearch-form {
                background-color: $white;
                border: solid 2px $blue1;
                margin-left: 0;

                .siteSearch-form-filtersButton {
                    border-color: #959ea8;
                }
            }
        }

        &.searchOpen {
            .siteSearch-dropdownContainer {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
            }

            .siteSearch-form {
                background-color: $white;
                border: solid 2px $blue1;

                .siteSearch-form-filtersButton {
                    border-color: #959ea8;
                }
            }
        }

        &.filtersOpen {
            .siteSearch-dropdown {
                display: none;
            }

            .siteSearch-filters {
                display: flex;
            }
        }


        .siteSearch-form {
            .siteSearch-form-submitButton:active,
            .siteSearch-form-submitButton:focus,
            .siteSearch-form-filtersButton:active,
            .siteSearch-form-filtersButton:focus {
                border: 1px solid $blue1 !important;
                outline: none !important;
            }
        }

    }

    &-mobile {
        &-closeButton {
           appearance: none;
           background: transparent;
           border: none;
           display: flex;
           align-items: center;
           justify-content: center;
           height: 100%;
           padding: 0 1rem;

            &-container {
                height: 5.2rem;
                margin-left: 1rem;
                display: none;

                @media only screen and (max-width: 992px) {
                    display: block;
                }
            }
        }
    }

    &-dropdownContainer {
        position: absolute;
        left: 0;
        top: calc(100% + .75rem);
        width: 100%;
        z-index: 10;
        padding: 0 2rem 0 1rem;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transform: translateY(1rem);
        transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &-filters {
        display: none;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 2.4rem;
        background-color: $white;
        border: solid 1px $grey4;
        border-radius: .8rem;
        box-shadow: 0 2px 5px 0 rgba(25, 34, 43, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);

        @media (max-width: 767px) {
            height: calc(100vh - 90px);
            overflow-y: scroll;
        }

        &-searchBy {
            .nav-main-form-selector {
                @media (max-width: 767px) {
                    display: block !important;
                }
            }
        }

        .radio-input {
            width: auto !important;
            display: inline-flex !important;
        }

        .search-filter-input {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.672861 0.163757H9.32714C9.92584 0.163757 10.2252 0.886909 9.80139 1.31071L5.47593 5.63953C5.21358 5.90188 4.78642 5.90188 4.52407 5.63953L0.198608 1.31071C-0.225193 0.886909 0.0741585 0.163757 0.672861 0.163757Z' fill='%234F5963'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: calc(100% - 1.5rem) center;
        }

        &-label,
        label {
            color: #19222b;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.4rem;
            margin-bottom: 0.5rem;
        }

        &-inputs {
            display: flex !important;
            flex-direction: column;
            gap: 1rem;

            .form-group:last-of-type {
                margin-bottom: 0;
            }
        }

        input[name="filters_price_max"],
        input[name="filters_price_min"] {
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.62186 7.56563L2.98749 6.52812C2.49374 6.3875 2.09374 5.99062 2.01561 5.48125C1.89374 4.6875 2.50936 4 3.28124 4H5.58749C6.08436 4 6.57187 4.15625 6.97499 4.44063C7.17499 4.58125 7.44374 4.55937 7.61561 4.3875L8.33124 3.67188C8.54374 3.45937 8.52186 3.10313 8.28436 2.91875C7.51249 2.32188 6.57499 2 5.58749 2H5.49999V0.5C5.49999 0.225 5.27499 0 4.99999 0H3.99999C3.72499 0 3.49999 0.225 3.49999 0.5V2H3.42186C1.88436 2 0.465615 2.99375 0.0968649 4.4875C-0.33751 6.24375 0.728115 7.9625 2.37811 8.43437L6.01249 9.47188C6.50624 9.6125 6.90624 10.0094 6.98436 10.5188C7.10624 11.3125 6.49061 12 5.71874 12H3.41249C2.91561 12 2.42811 11.8438 2.02499 11.5594C1.82499 11.4187 1.55624 11.4406 1.38436 11.6125L0.66874 12.3281C0.45624 12.5406 0.478115 12.8969 0.715615 13.0813C1.48436 13.6781 2.42186 14 3.41249 14H3.49999V15.5C3.49999 15.775 3.72499 16 3.99999 16H4.99999C5.27499 16 5.49999 15.775 5.49999 15.5V14H5.57811C7.11561 14 8.53437 13.0062 8.90312 11.5125C9.33749 9.75625 8.27186 8.0375 6.62186 7.56563Z' fill='%234F5963'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: 1.5rem center;
            padding-left: 3rem;
        }

        &-actions {
            display: flex !important;
            justify-content: flex-end;
            margin-top: 1rem;
            gap: 1rem;

            .btn {
                margin: 0;
            }

            @media (max-width: 576px) {
                flex-direction: column-reverse;

                .btn {
                    margin-right: 0;
                }
            }
        }

        .form-group {
            margin-bottom: 0;
        }

        &-projectFilters {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &-formGroup {
                display: flex;
                gap: 1.5rem;

                @media (max-width: 767px) {
                    flex-direction: column;
                    gap: 1rem;
                }

                > div {
                    flex: 1 1 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-dropdown {
        width: 100%;
        padding: 2.4rem;
        background-color: $white;
        border: solid 1px $grey4;
        border-radius: .8rem;
        box-shadow: 0 2px 5px 0 rgba(25, 34, 43, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);

        &-section {
            &:not(:first-of-type) {
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: 1px solid $grey4;
            }

            &:empty {
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }
        }

        &-sectionHeader {
            font-size: 1.4rem;
            color: $grey2;
            margin-top: 0;
            margin-bottom: 1rem;
            font-weight: normal;

            &-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &-actionButtons {
            svg {
                height: 1.4rem;
                width: 1.6rem;
                fill: currentColor;
                margin-top: -3px;
            }

            span {
                display: inline;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            @media (min-width: 767px) and (max-width: 992px) {
                a:first-child {
                    margin-bottom: 1rem;
                }
            }

        }

        &-resultList {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            list-style-type: none;
            padding: 0;
        }

        &-showFiltersButton {
            align-items: center;
            appearance: none;
            background-color: transparent;
            border: none;
            display: flex;
            color: $blue1 !important;
            font-size: 1.6rem !important;
            font-weight: 500 !important;

            &:hover {
                text-decoration: underline;
            }

            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 1rem;

                path {
                    fill: currentColor;
                }
            }
        }

        &-searchResult {
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $grey1;
                font-size: 1.6rem;
                padding: 0.5rem 1rem;
                border-radius: 4px;
                transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);

                &:hover {
                    background-color: #f0f4f7;
                }

                svg {
                    flex: 0 0 auto;
                    &:first-child {
                        margin-right: 1rem;
                    }
                }

                span {
                    flex: 2 1 auto;
                }
            }

            &-followIcon {
                height: 2rem;
                width: 2rem;
                margin-left: auto;
            }

            &-keyword {
                word-wrap: break-word;
                word-break: break-word;
                color: $grey1;
            }

            &-type {
                font-size: 1.2rem;
                color: $grey2
            }
        }

        &-recentSearch-clear {
            appearance: none;
            background-color: transparent;
            border: none;
            font-size: 1.4rem !important;
            padding: 0;
            color: $blue1 !important;
            font-weight: 500 !important;

            &:hover {
                text-decoration: underline;
            }
        }

        &-browseBy {
            display: none !important;

            @media only screen and (max-width: 768px) {
                display: block !important;
            }
        }
    }

    &-form {
        --text-color: $grey1;
        --bg-grey: #f0f4f7;
        --border-color: $grey5;
        --accent-color: $blue1;

        display: flex;
        flex: 1 1 auto;
        align-items: center;
        height: 52px;
        gap: .8rem;
        padding: 0 1rem !important;
        border-radius: 9999px;
        border: solid 2px $grey5;
        background-color: $grey5;
        transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);

        &-clearKeywords {
            display: flex;
            position: relative;
            z-index: 9999;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: $grey1 !important;
            appearance: none;
            background-color: transparent;
            border: none;
            margin-right: 0 !important;
            transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
            opacity: 0;
            pointer-events: none;
            visibility: hidden;

            &.show {
                pointer-events: all;
                display: flex !important;
            }

            svg {
                height: 2rem;
                width: 2rem;

                path {
                    fill: currentColor;
                }
            }

            &::before {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 2rem;
                width: 1px;
                background-color: $grey4;
            }
        }

        &-submitButton,
        &-filtersButton {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text-dark;
            transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &-submitButton {
            appearance: none;
            background-color: transparent !important;
            border: none !important;

            svg {
                height: 16px;
                width: 16px;
                fill: currentColor;
            }
        }

        &-filtersButton {
            height: 3.6rem;
            width: 3.6rem;
            position: relative;
            border-radius: 50px;
            border: solid 1px transparent;
            background-color: transparent;

            svg {
                position: absolute;
                left: 7px;
                top: 8px;
                z-index: -1;
                height: 20px;
                width: 20px;
                fill: currentColor;
            }
        }

        &-searchInput {
            flex: 1 1 0%;
            height: 100%;
            background-color: transparent;
            border: none;
            padding: 0;
            caret-color: $blue1;

            &:focus {
                border: none !important;
                box-shadow: none !important;
            }
        }
    }

}

.hasKeywords {
    .siteSearch-form-clearKeywords {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
    }
}

.siteSearch-form-searchInput {
    outline: none;
}

.header-loggedin {
    .siteSearch {
        &-container {
            @media only screen and (max-width: 768px) {
                .siteSearch-form {
                    width: 100%;
                }

                .siteSearch-dropdownContainer {
                    position: fixed;
                    top: 0 !important;
                    left: 0;
                    height: 100vh;
                    width: 100vw;
                    width: 100%;
                    padding: 2rem;
                    background: $white;

                }

                .siteSearch-dropdown,
                .siteSearch-filters {
                    box-shadow: none;
                    border: none;
                    border-radius: 0;
                    border-top: solid 1px $grey4;
                    margin-top: 2rem;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &.siteSearch-mobileVisible {
                opacity: 1;
                visibility: visible;
                pointer-events: all;

                .siteSearch-dropdownContainer {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                    transform: translateY(0);
                }

                .siteSearch-form {
                    background-color: $white;
                    border: solid 2px $blue1;

                    .siteSearch-form-filtersButton {
                        border-color: #959ea8;
                    }
                }
            }
        }
    }
}

.header-loggedin .siteSearch-mobile-formContainer {
    display: none;

    @media only screen and (max-width: 768px) {
        display: flex;
    }
}

.header-not-loggedin .siteSearch-mobile-formContainer {
    display: none;

    @media only screen and (max-width: 992px) {
        display: flex;
    }
}

.header-not-loggedin {
    .siteSearch {
        &-container {
            @media only screen and (max-width: 992px) {
                .siteSearch-form {
                    width: 100%;
                }

                .siteSearch-dropdownContainer {
                    position: fixed;
                    top: 0 !important;
                    left: 0;
                    height: 100vh;
                    width: 100vw;
                    width: 100%;
                    padding: 1rem;
                    background: $white;

                }

                .siteSearch-dropdown,
                .siteSearch-filters {
                    box-shadow: none;
                    border: none;
                    border-radius: 0;
                    border-top: solid 1px $grey4;
                    margin-top: 2rem;
                }
            }

            @media only screen and (max-width: 768px) {
                .siteSearch-dropdown,
                .siteSearch-filters {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &.siteSearch-mobileVisible {
                opacity: 1;
                visibility: visible;
                pointer-events: all;

                .siteSearch-dropdownContainer {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                    transform: translateY(0);
                }

                .siteSearch-form {
                    background-color: $white;
                    border: solid 2px $blue1;

                    .siteSearch-form-filtersButton {
                        border-color: #959ea8;
                    }
                }
            }
        }
    }

    .siteSearch-dropdown-browseBy {
        display: none !important;
    }
}

.header.header-not-loggedin .siteSearch-container {
    @media only screen and (max-width: 992px) {
        margin-left: 3rem;
    }
}

.header.header-not-loggedin .nav-main:not(.expanded-search) .nav-main-form {
    width: 100% !important;
}

.siteSearch-filters-actions:nth-of-type(2) {
    display: none;
}

.siteSearch-filters,
.siteSearch-dropdown {

    @media (max-width: 767px) {
        height: calc(100vh - 90px);
        overflow-y: scroll;
    }

    @media screen and (max-height: 835px) and (min-width: 768px) {
        max-height: 500px;
        overflow-y: scroll;
    }

}

.nav-main-form.siteSearch-form {
    @media (max-width: 576px) {
        gap: 0 !important;

        .siteSearch-form-searchInput {
            margin-left: 0 !important;
        }
    }
}

.siteSearch-form-clearKeywords {
    @media (max-width: 576px) {
        display: none;
    }
}

.header-loggedin {
    .siteSearch-container {
        >.siteSearch-form {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}

// Mobile general theming
.siteSearch-mobile-formContainer {

    .siteSearch-form-searchInput {
        margin-left: 0;
    }

    .siteSearch-form-clearKeywords {
        display: none;
    }

    .siteSearch-form {
        margin-left: 3.5rem;

        // For tablet only.
        @media screen and (min-width: 768px) and (max-width: 992px) {
            margin: 0 18%;
        }
    }

    @media screen and (max-width: 360px) {
        .siteSearch-form-searchInput {
            font-size: 1.2rem;

            &::placeholder {
                font-size: 1.2rem;
            }
        }

        .siteSearch-mobile-closeButton-container {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 330px) {
        .siteSearch-form-searchInput {
            font-size: 1rem;

            &::placeholder {
                font-size: 1rem;
            }
        }
    }
}

.show-caret-desktop {
    display: inline-block;

    @media screen and (min-width: 992px) {
        display: none !important;
    }
}

.nav-main-logo-link {
    z-index: 99999;

    @media (max-width: 768px) {
        width: 2.2rem;

        svg {
            pointer-events: none;
        }
    }
}

.nav-main-container {
    max-width: 100vw;
}

.nav-main-submenu-not-loggedin-btn-group {
    display: flex;
}
