/* LESS/CSS Color Syntax:
- 4-5 colour intervals (tints and shades) are expected when a new colour is added. At start, a corporate colour has been assigned N=1. Since these colours are heavily saturated, shades are assigned N=0 and tints are N=2 -> N=5.
- color definitions: $baseColourN, where baseColour is the family of brand colours and N is an integer with 0 as the darkest value and larger values represent increasingly brighter tints, maxing out at 5.
ie: color: $blue3;
- utility class syntax: propertyShortForm-baseColourN|N, where propertyShortForm is a shortened property and N is the value.
ie: @extend .bg-blue3(background-color: $blue3), .c-accent0 (color: $accent0,), .margin-bottom-larger (margin-bottom: 3em);
*/

/* Color LESS Var Declarations */
/* Use these in production */

$colors: (
	white: #FFFFFF,
	blue0: #004A81,
	blue1: #1171BB,
	blue2: #1F8FE6,
	blue3: #4DB1FF,
	blue4: #97D2FF,
	blue5: #DDF0FF,
	blueDisabled: #A9C7E4,
	ice0: #007DB3,
	ice1: #0098D5,
	ice2: #34BBF3,
	ice3: #89DDFF,
	ice4: #B9EBFF,
	ice5: #E7F8FF,
	green0: #238551,
	green1: #30A165,
	green2: #48CC86,
	green3: #74E3A8,
	green4: #ADF5CF,
	green5: #E6FFF2,
	yellow0: #EA9F06,
	yellow1: #FFBC33,
	yellow2: #FFD25A,
	yellow3: #FFDF8B,
	yellow4: #FFEAB2,
	yellow5: #FFF7E2,
	red0: #AE2206,
	red1: #D1462A,
	red2: #F86648,
	red3: #FD8C75,
	red4: #FFC4B8,
	red5: #FFEFEC,
	grey: #F6F8FA,
	grey0: #313E4B,
	grey1: #4F5963,
	grey2: #6E7681,
	grey3: #959EA8,
	grey4: #C9D0D9,
	grey5: #F0F4F7,
	darkGrey: #333333,
	purple0: #391684,
	purple1: #552AB1,
	purple2: #764DCC,
	purple3: #9E7FE3,
	purple4: #CCB9F5,
	purple5: #E7DCFF,
);

// Original variables
$white: map-get($colors, white);
$blue0: map-get($colors, blue0);
$blue1: map-get($colors, blue1);
$blue2: map-get($colors, blue2);
$blue3: map-get($colors, blue3);
$blue4: map-get($colors, blue4);
$blue5: map-get($colors, blue5);
$blueDisabled: map-get($colors, blueDisabled);

$ice0: map-get($colors, ice0);
$ice1: map-get($colors, ice1);
$ice2: map-get($colors, ice2);
$ice3: map-get($colors, ice3);
$ice4: map-get($colors, ice4);
$ice5: map-get($colors, ice5);

$green0: map-get($colors, green0);
$green1: map-get($colors, green1);
$green2: map-get($colors, green2);
$green3: map-get($colors, green3);
$green4: map-get($colors, green4);
$green5: map-get($colors, green5);

$yellow0: map-get($colors, yellow0);
$yellow1: map-get($colors, yellow1);
$yellow2: map-get($colors, yellow2);
$yellow3: map-get($colors, yellow3);
$yellow4: map-get($colors, yellow4);
$yellow5: map-get($colors, yellow5);

$red0: map-get($colors, red0);
$red1: map-get($colors, red1);
$red2: map-get($colors, red2);
$red3: map-get($colors, red3);
$red4: map-get($colors, red4);
$red5: map-get($colors, red5);

$grey: map-get($colors, grey);
$grey0: map-get($colors, grey0);
$grey1: map-get($colors, grey1);
$grey2: map-get($colors, grey2);
$grey3: map-get($colors, grey3);
$grey4: map-get($colors, grey4);
$grey5: map-get($colors, grey5);
$darkGrey: map-get($colors, darkGrey);

$purple0: map-get($colors, purple0);
$purple1: map-get($colors, purple1);
$purple2: map-get($colors, purple2);
$purple3: map-get($colors, purple3);
$purple4: map-get($colors, purple4);
$purple5: map-get($colors, purple5);

$accent0: map-get($colors, red0);
$accent1: map-get($colors, red1);
$accent2: map-get($colors, red2);
$accent3: map-get($colors, red3);
$accent4: map-get($colors, red4);
$accent5: map-get($colors, red5);

$default: map-get($colors, grey2);
$primary: map-get($colors, blue1);
$info: map-get($colors, ice1);
$success: map-get($colors, green1);
$warning: map-get($colors, yellow1);
$danger: map-get($colors, red1);

/* Redundant. Used to satisfy the base bootstrap expectation of 3rd party LESS files. */
$brand-primary:         map-get($colors, blue1);
$brand-danger:          map-get($colors, accent1);
$brand-info:            map-get($colors, ice1);
$brand-warning:         map-get($colors, yellow1);
$brand-success:         map-get($colors, green1);

// Theme Colours (map)
$theme-colors: (
	"default":  $default,
	"primary":  $blue1,
	"success":  $success,
	"info":     $info,
	"warning":  $warning,
	"danger":   $danger
);

// Text Colours

$text-colors: (
	text: #444444,
	text-light: #666666,
	text-ex-light: #999999,
	text-dark: #19222B,
	text-inverse: #DFDFDF,
	text-color: #444444,
	link-color: #004A81,
	placeholder: $grey2
);

$text: map-get($text-colors, text);
$text-light: map-get($text-colors, text-light);
$text-ex-light: map-get($text-colors, text-ex-light);
$text-dark: map-get($text-colors, text-dark);
$text-inverse: map-get($text-colors, text-inverse);
$text-color: map-get($text-colors, text-color);
$link-color: map-get($text-colors, link-color);
$placeholder: map-get($text-colors, placeholder);
$input-placeholder-color: $placeholder;

// Borders *****************************************************************************
$border-color: map-get($colors, grey4);
